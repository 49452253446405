<template>
    <v-list-item>
        <v-list-item-content>Is Prepaid?:</v-list-item-content>
        <v-list-item-content style="display: inline-block">
            <g-payment-status-chip v-if="status" :text="prepaymentText(status)" color="deep-purple darken-3"/>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    import GPaymentStatusChip from "../GPaymentStatusChip";
    export default {
        name: "ListItemPrepaymentStatus",
        components: {GPaymentStatusChip},
        props: {
            status: {
                type: Number,
                default: () => 0
            }
        },
        methods: {
            prepaymentText(value) {
                if(value === 1){
                    return 'Prepaid';
                } else if(value === 2) {
                    return 'Completed';
                } else {
                    return '';
                }
            }
        }
    }
</script>

<style scoped>

</style>