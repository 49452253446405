<template>
    <v-timeline>
        <v-timeline-item
                v-for="(item, i) in timelineData"
                :key="i"
                :color="item.color"
                small
        >
            <template v-slot:opposite>
        <span
                :class="`headline font-weight-bold ${item.color}--text`"
                v-text="item.title"
        ></span>
            </template>
            <v-card class="elevation-2">
                <v-card-title :class="`text-h6 font-weight-light mb-4 ${item.color}--text`">
                    {{item.subtitle}}
                </v-card-title>
                <v-card-text v-html="item.description"></v-card-text>
            </v-card>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
    export default {
        name: "TimelineComponent",
        props: {
            timelineData: Array,
            default: () => []
        }
    }
</script>

<style scoped>

</style>