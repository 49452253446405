<template>
    <v-list-item>
        <v-list-item-content>{{title}}:</v-list-item-content>
        <v-list-item-content style="display: inline-block">
            <g-payment-status-chip v-if="isDeleted && status === 900" text="Removed" color="blue"/>
            <g-payment-status-chip v-else-if="isDeleted" text="Deleted" color="blue"/>
            <g-payment-status-chip v-else-if="status === 100"/>
            <g-payment-status-chip v-else-if="status === 200" color="green" text="Paid"/>
            <g-payment-status-chip v-else-if="status === 300" color="lime" text="Pending"/>
            <g-payment-status-chip v-else-if="status === 400" color="yellow" text="Refund"/>
            <g-payment-status-chip v-else-if="status === 600" color="orange" text="Republish"/>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    import GPaymentStatusChip from "../GPaymentStatusChip";
    export default {
        name: "ListItemPaymentStatus",
        components: {GPaymentStatusChip},
        props: {
            title: {
                type: String,
                default: function () {
                    return '';
                }
            },
            isDeleted: {
                type: [Boolean, Number],
                default: function () {
                    return false;
                }
            },
            status: {
                type: [String, Number],
                default: function () {
                    return '';
                }
            },
        }
    }
</script>

<style scoped>

</style>